<template>
	<AdminUsers></AdminUsers>
</template>

<script>
import AdminUsers from "@/components/shared/users/Index.vue";

export default {
	name: "AdminUsersPage",

	components: {
		AdminUsers,
	},
};
</script>

<style></style>
