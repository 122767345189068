<template>
	<v-card flat height="100%" class="wr_grey_1">
		<v-card-text class="px-15">
			<!-- Choose profile for new/existing user -->
			<v-tabs
				v-model="create_for"
				active-class="black--text font-weight-bold"
				slider-color="error"
				grow
			>
				<v-tab
					v-for="(tab, index) in options"
					:key="index"
					:href="`#${tab.value}`"
					class="title wr_grey_1"
				>
					{{ $t(tab.name) }}
					<v-icon right :color="create_for == tab.value ? 'error' : null">{{
						$vuetify.icons.values[tab.icon]
					}}</v-icon>
				</v-tab>
			</v-tabs>

			<v-tabs-items v-model="create_for" class="pt-10 wr_grey_1">
				<v-form ref="form">
					<div>
						<v-row v-if="create_for == new_user">
							<!-- username -->
							<v-col sm="4">
								<div class="label-wr">{{ $t("app.placeholder.username") }}</div>
								<v-text-field
									v-model="fields.login_user"
									outlined
									color="greenAccent2"
									background-color="white"
									:rules="[rules.required]"
								></v-text-field>
							</v-col>
							<!-- password -->
							<v-col sm="4">
								<div class="label-wr">{{ $t("app.placeholder.password") }}</div>
								<v-text-field
									v-model="fields.password"
									outlined
									color="greenAccent2"
									background-color="white"
									:rules="[rules.required, rules.strongPassword]"
									:append-icon="
										$vuetify.icons.values[show_password ? 'eye' : 'eye_off']
									"
									:type="show_password ? 'text' : 'password'"
									@click:append="show_password = !show_password"
								></v-text-field>
							</v-col>
							<!-- external id -->
							<v-col sm="4">
								<div class="label-wr" v-html="$t('app.unique_id')"></div>
								<v-text-field
									v-model="fields.external_id"
									outlined
									color="greenAccent2"
									background-color="white"
									persistent-hint
								>
								</v-text-field>
							</v-col>
							<!-- first name -->
							<v-col sm="4">
								<div class="label-wr">{{ $t("app.first_name") }}</div>
								<v-text-field
									v-model="fields.name"
									outlined
									color="greenAccent2"
									background-color="white"
									:rules="[rules.required]"
								></v-text-field>
							</v-col>
							<!-- last name -->
							<v-col sm="4">
								<div class="label-wr">{{ $t("app.last_name") }}</div>
								<v-text-field
									v-model="fields.last_name"
									outlined
									color="greenAccent2"
									background-color="white"
									:rules="[rules.required]"
								></v-text-field>
							</v-col>
							<!-- email -->
							<v-col sm="4">
								<div class="label-wr">{{ $t("app.email_address") }}</div>
								<v-text-field
									v-model="fields.email"
									outlined
									color="greenAccent2"
									background-color="white"
								></v-text-field>
							</v-col>
						</v-row>
						<!-- user id -->
						<v-row v-if="create_for == existing_user">
							<v-col>
								<div class="label-wr">{{ $t("app.user_id") }}</div>
								<v-text-field
									v-model="fields.user_id"
									outlined
									color="greenAccent2"
									background-color="white"
									type="number"
									:rules="[rules.required]"
								></v-text-field>
							</v-col>
						</v-row>
						<v-row>
							<!-- gender -->
							<v-col sm="4">
								<div class="label-wr">{{ $t("app.gender") }}</div>
								<v-select
									v-model="fields.gender"
									:items="$defines.GENDER"
									item-text="name"
									item-value="value"
									outlined
									:rules="[rules.required]"
									color="greenAccent2"
									background-color="white"
								>
									<template v-slot:selection="{ item }">
										{{ $t(item.name) }}
									</template>
									<template v-slot:item="{ item }">
										{{ $t(item.name) }}
									</template>
								</v-select>
							</v-col>
							<!-- user role -->
							<v-col sm="4">
								<div class="label-wr">{{ $t("app.role") }}</div>
								<v-select
									v-model="fields.user_type"
									:items="$defines.USER_ROLES_ARR"
									item-value="value"
									color="greenAccent2"
									background-color="white"
									outlined
									:rules="[rules.required]"
								>
									<template v-slot:selection="{ item }">
										{{ $t(`${item.name}`) }}
									</template>
									<template v-slot:item="{ item }">
										{{ $t(`${item.name}`) }}
									</template>
								</v-select>
							</v-col>
							<!-- user language -->
							<v-col sm="4">
								<div class="label-wr">{{ $t("app.language") }}</div>
								<v-select
									v-model="fields.user_lang"
									:items="$defines.LOCALES"
									item-text="name"
									item-value="code"
									outlined
									color="greenAccent2"
									background-color="white"
									:rules="[rules.required]"
								>
								</v-select>
							</v-col>
							<!-- menu -->
							<v-col v-if="menus.length" sm="6">
								<div class="label-wr">{{ $t("app.menu") }}</div>
								<v-select
									v-model="fields.menu_id"
									:items="menus"
									item-text="title"
									item-value="id"
									outlined
									color="greenAccent2"
									background-color="white"
									:rules="[rules.required]"
								>
								</v-select>
							</v-col>
							<!-- plan -->
							<v-col v-if="plans.length">
								<div class="label-wr">{{ $t("app.plan") }}</div>
								<v-select
									v-model="fields.plan_id"
									:items="plans"
									item-text="name"
									item-value="id"
									outlined
									color="greenAccent2"
									background-color="white"
									:rules="[rules.required]"
								>
								</v-select>
							</v-col>
							<!-- plan -->
							<v-col v-if="paper_schemes.length">
								<div class="label-wr">{{ $t("app.paper_scheme") }}</div>
								<v-select
									v-model="fields.paper_scheme"
									:items="paper_schemes"
									item-text="name"
									item-value="name"
									outlined
									color="greenAccent2"
									background-color="white"
									:rules="[rules.required]"
								>
								</v-select>
							</v-col>
						</v-row>
					</div>
				</v-form>
			</v-tabs-items>

			<v-card-text align="center" class="mt-5">
				<AppButton
					label="app.save"
					icon="save"
					:min_width="150"
					:large="true"
					@click="submit()"
				></AppButton>
			</v-card-text>
		</v-card-text>
	</v-card>
</template>

<script>
import rules from "@/utils/validation";

export default {
	name: "AdminUsers",

	data() {
		return {
			rules,
			show_password: false,
			menus: [],
			plans: [],
			paper_schemes: [],
			// form fields
			fields: {
				user_id: null,
				login_user: null,
				external_id: null,
				name: null,
				last_name: null,
				password: null,
				user_lang: "en",
				user_type: "student",
				gender: "male",
				email: null,
				menu_id: null,
				plan_id: null,
				paper_scheme: null,
			},
			// profile creating options
			new_user: 0,
			existing_user: 1,
			create_for: 0,
			options: [
				{
					name: "app.create_new_user",
					value: 0,
					icon: "plus",
				},
				{
					name: "app.create_profile_for_existing_user",
					value: 1,
					icon: "user",
				},
			],
		};
	},

	mounted() {
		this.init();
	},

	methods: {
		async init() {
			// get menus list
			this.menus = await this.$store.dispatch("research/listMenus");
			// get plans list
			let res = await this.$store.dispatch("research/listPlans");
			if (res) {
				this.plans = res.results;
			}
			
			// get paper_schemes
			this.paper_schemes = await this.$store.dispatch(
				"research/listPaperSchemes",
			);
		},

		async submit() {
			try {
				this.$loader.start();
				// validate form
				if (!this.$refs.form.validate()) {
					throw new Error("app.ntfy.err.fill_required_fields", {
						cause: "werCustom",
					});
				}
				// copy the fields
				let obj = this.fields;
				// User id is not needed if new user
				if (this.create_for == this.new_user) {
					delete obj["user_id"];
				}
				// create profile
				await this.$store.dispatch("user/createProfile", obj);
				// success notification
				this.$announce.success("app.ntfy.succ.profile_created");
				// Reset the form
				this.resetForm();
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.$loader.stop();
			}
		},

		resetForm() {
			// Reset using method to avoid the validation errors
			this.$refs.form.reset();
			// Re-fill with default fields
			this.fields = {
				user_id: null,
				login_user: null,
				external_id: null,
				name: null,
				last_name: null,
				password: null,
				user_lang: "en",
				user_type: "student",
				gender: "male",
				email: null,
				plan_id: null,
				menu_id: null,
			};
		},
	},
};
</script>

<style></style>
